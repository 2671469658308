/** @jsx jsx */

import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import { Link } from 'gatsby'

// There are three types of links:
// - Internal links to other pages within the same app
// - External links, to other domains
// - Hash links (#introduction), for the same page.

export const HyperLink = ({ href, children, ...delegated }) => (
  <StyledLink href={href} target="_blank" {...delegated}>
    {children}
  </StyledLink>
)

const StyledLink = styled.a`
  color: var(--color-link);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  transition: box-shadow 100ms ease 0s;

  :hover {
    box-shadow: 0px 2px 0px var(--color-link);
  }
`

export const InternalLink = ({
  to,
  children,
  activeStyle = null,
  noUnderline = false,
  isNavigationItem = false,
  spaced = false,
}) => (
  <Link
    to={to}
    activeStyle={to === '/' ? null : activeStyle}
    partiallyActive={true}
    css={css`
      color: ${isNavigationItem ? 'var(--color-primary)' : 'var(--color-link)'};
      font-weight: ${isNavigationItem ? '400' : '500'};
      line-height: 20px;
      margin: ${spaced ? '12px 0px 12px 16px' : '0px'};
      position: relative;
      transition: box-shadow 100ms ease 0s;

      :hover {
        box-shadow: ${noUnderline ? 'none' : '0px 2px 0px var(--color-link)'};
      }
    `}
  >
    {children}
  </Link>
)
